import { Form, Row, Col } from "react-bootstrap";
import "../App.css";

function CvFormRow(label, type) {
  return (
    <Row className="mb-2">
      <Col md={2}>
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col md={6}>
        <Form.Group>
          <Form.Control size="lg" type={type} placeholder="..." />
        </Form.Group>
      </Col>
    </Row>
  );
}

export default CvFormRow;
