import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function Legal() {
  return (
    <div className="normalPageDiv">
      <h2>Legal</h2>
      Legal info
    </div>
  );
}

export default Legal;
