import { Form, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

import React, { useState } from "react";

function SearchSubcontracts() {
  const [result, setResult] = useState(null);

  function setAlert(e) {
    setResult(e.target.value);
  }

  function alertIt() {
    alert(result);
  }

  function clearForm() {
    setResult(null);
    document.forms["subcontractsForm"].reset();
  }

  return (
    <div className="normalPageDiv">
      <h2>Search for Available Subcontracts in my Ward</h2>
      <div className="subcontractsFormDiv row col-12">
        <Form id="subcontractsForm" autoComplete="off">
          <Row className="mb-2">
            <Col className="mb-2 col-4 col-md-2">
              <Form.Label>Ward #</Form.Label>
            </Col>
            <Col className="mb-2 col-12 col-md-6">
              <Form.Group>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setAlert(e)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Button variant="primary" size="lg" onClick={() => alertIt()}>
                Search
              </Button>
            </Col>
          </Row>
        </Form>{" "}
      </div>
      <div className="jobsTable row table-responsive">
        <Table striped bordered hover className="col-12">
          <thead>
            <tr>
              <th>Contractor</th>
              <th>Position</th>
              <th>Workers</th>
              <th>Start</th>
              <th>Days</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Hm civils</td>
              <td>Bricklayer</td>
              <td>11</td>
              <td>1 Nov 2021</td>
              <td>12</td>
              <td>Apply</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default SearchSubcontracts;
