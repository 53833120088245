import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function ProjectItem(
  imgSrc,
  project,
  contractor,
  jobs,
  subcontractors,
  csi,
  dates,
  wardnum,
  saveProjData
) {
  const [goToProject, setGoToProject] = useState(false);

  function goToPage() {
    setGoToProject(true);
    saveProjData(
      project,
      contractor,
      jobs,
      subcontractors,
      csi,
      dates,
      wardnum
    );
  }

  let projectName = project.split(" ").join("-");
  projectName = projectName.toLowerCase();
  console.log("project name is: " + projectName);

  let showPage;
  let path = `/select-ward/my-ward-${wardnum}/current-projects/${projectName}`;

  if (goToProject === true) {
    showPage = <Navigate to={path} />;
  } else {
    showPage = (
      <Link to={path} className="noStyleLink">
        <div className="projectItem row col-12">
          <div className="projectImageDiv col-12 col-md-4">
            <Image src={imgSrc} alt={project} className="projectImage" />
          </div>
          <div className="projectInfo col-12 col-md-4">
            <ul>
              <li>
                Project: <b> {project}</b>
              </li>
              <li>
                Contractor: <b>{contractor}</b>
              </li>
              <li>
                Local Jobs: <b>{jobs}</b>
              </li>
              <li>
                Local Subcontracters: <b>{subcontractors}</b>
              </li>
              <li>
                CSI: <b>{csi}</b>
              </li>
              <li>
                Est. Dates: <b>{dates}</b>
              </li>
            </ul>
          </div>
        </div>{" "}
      </Link>
    );
  }
  return showPage;
}

export default ProjectItem;
