import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container, Navbar } from "react-bootstrap";
import logo from "../logosvg.svg";
import "../App.css";

function Header() {
  return (
    <header className="App-header">
      <Navbar
        collapseOnSelect
        variant="light"
        expand="lg"
        className="navbar-light"
      >
        {" "}
        <Container>
          <Navbar.Brand href="/">
            {" "}
            <div className="topHeader">
              <div className="logo">
                <img src={logo} className="App-logo" alt="logo" />
                <h1>
                  Local<span className="black">Link</span>
                </h1>
              </div>
            </div>
            <div className="bottomHeader">
              Empowering South African Communities
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Item>
                <Nav.Link href="/">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/">Success Stories</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/">
                  <span style={{ color: "red" }}>Whistleblower</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/search-jobs">Search Jobs</Nav.Link>
              </Nav.Item>
              <NavDropdown title="Dropdown" className="navDropdownParent">
                <NavDropdown.Item
                  className="navDropdown"
                  href="/search-subcontracts"
                >
                  Search Subcontracts
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="navDropdown"
                  href="/become-a-community-liaison-officer"
                >
                  Become a CLO
                </NavDropdown.Item>
                <NavDropdown.Item className="navDropdown" href="/training">
                  Training
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="navDropdown"
                  href="/contact-work-seekers"
                >
                  Contact Work Seekers
                </NavDropdown.Item>
                <NavDropdown.Item className="navDropdown" href="/submit-cv">
                  Submit My CV
                </NavDropdown.Item>
                <NavDropdown.Item className="navDropdown" href="/contact">
                  Contact
                </NavDropdown.Item>
                <NavDropdown.Item className="navDropdown" href="/about">
                  About
                </NavDropdown.Item>
                <NavDropdown.Item className="navDropdown" href="/legal">
                  Legal
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Nav className="justify-content-end">
              <Nav.Item>
                <Nav.Link href="/register">Register</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/login">Login</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <div className="menuLinksRight">
          <Nav>
            <Nav.Item>
              <Nav.Link href="/register">Register</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/login">Login</Nav.Link>
            </Nav.Item>
          </Nav>
        </div> */}
      {/* </div>

      <div className="bottomHeader">Empowering South African Communities</div> */}
    </header>
  );
}

export default Header;
