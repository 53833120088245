import Button from "react-bootstrap/Button";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { useParams } from "react-router";
import Image from "react-bootstrap/Image";
import bigImg from "../workerImg.jpg";
import smallImg from "../workerImg.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function SpecificProject(props) {
  const [goBack, setGoBack] = useState(false);
  let showProjects;
  let { wardnum, projectname } = useParams();

  let wardNumOriginal = wardnum;
  wardnum = wardnum.split("-").join(" ");
  wardnum = wardnum.replace("my ward", "");

  projectname = projectname.split("-").join(" ");

  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  function titleCase(string) {
    return string
      .split(" ")
      .map((x) => capitalizeFirstLetter(x))
      .join(" ");
  }

  let finalProjName = titleCase(projectname);
  let pathBack = `/select-ward/${wardNumOriginal}/current-projects`;

  function redirect() {
    setGoBack(true);
  }

  if (goBack === true) {
    showProjects = <Navigate to={pathBack} />;
  } else {
    showProjects = (
      <div>
        <div className="projectsRow col-12">
          <Button variant="primary" onClick={() => redirect()}>
            Back
          </Button>
          <h2>
            Ward {wardnum} | Project: {finalProjName}{" "}
          </h2>
        </div>
        <div className="infoAndImages">
          <div className="specProjectInfo">
            <ul>
              <li>
                Contractor: <b>HM Civils</b>
              </li>
              <li>
                Local Jobs: <b>34</b>{" "}
              </li>
              <li>
                Local Subcontracters: <b>9</b>{" "}
              </li>
              <li>
                CSI: <b>R25k</b>{" "}
              </li>
              <li>
                Est. Dates: <b>1 Oct 2020 to 31 Dec 2022</b>{" "}
              </li>
            </ul>
          </div>
          <div className="imagesRow">
            <div className="specProjBigImgDiv">
              <Image
                src={bigImg}
                alt={finalProjName}
                className="specProjectBigImage"
              />
            </div>
            <div className="specProjSmallImagesDiv">
              <Image
                src={smallImg}
                alt={finalProjName}
                className="specProjectSmallImage"
              />
              <Image
                src={smallImg}
                alt={finalProjName}
                className="specProjectSmallImage"
              />
              <Image
                src={smallImg}
                alt={finalProjName}
                className="specProjectSmallImage"
              />
              <Image
                src={smallImg}
                alt={finalProjName}
                className="specProjectSmallImage"
              />
              <Image
                src={smallImg}
                alt={finalProjName}
                className="specProjectSmallImage"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div className="normalPageDiv">{showProjects}</div>;
}

export default SpecificProject;
