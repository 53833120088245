import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import ThirdBlockItems from "./ThirdBlockItems";
import ThirdBlockItemsRight from "./ThirdBlockItemsRight";
import WideBlocks from "./WideBlocks";
import "../App.css";

function Home() {
  return (
    <Container fluid>
      {/* Top banner section of page */}
      <div className="banner back-image">
        {/* <div className="opacity"> */}
        <div className="bannerLeft opacity">
          <h1>Local Success Story</h1>
          <h5>
            How service delivery improves when the community gets involved.
          </h5>
          <div className="bannerButton">
            <Button variant="primary" size="sm">
              Read Article
            </Button>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className="myWardSection">
        <h2>My Ward</h2>
        <div className="row rows text-center text-md-start">
          {" "}
          <Link to="/select-ward" className="myWardSectionLeft col-12 col-md-6">
            {" "}
            <h5>Projects and Opportunities</h5>
          </Link>
          <Link to="/" className="myWardSectionRight col-12 col-md-6">
            <h5>Whistleblower</h5>
          </Link>
        </div>
      </div>

      <div className="opportunitiesSection">
        <h2>Opportunities</h2>
        <div className="row row2">
          {" "}
          {ThirdBlockItems(
            "/search-jobs",
            "Search Jobs in My Ward",
            "Semi, Skilled and Unskilled"
          )}
          {ThirdBlockItems(
            "/search-subcontracts",
            "Search Subcontracts",
            "Available in my ward"
          )}
          {ThirdBlockItems(
            "/become-a-community-liaison-officer",
            "Become a CLO",
            "Community Liason"
          )}
          {ThirdBlockItems(
            "/training",
            "Training",
            "Expand your Opportunities"
          )}
          {WideBlocks(
            "/contact-work-seekers",
            "Contact Work Seekers",
            "For contractors via ward CLOs"
          )}
          {WideBlocks(
            "/submit-cv",
            "Submit my CV",
            "Labourers and Subcontracters"
          )}
          {/* End of row2 div */}
        </div>

        {/* End of opportunitiesSection div */}
      </div>

      {/* End of main container div */}
    </Container>
  );
}
export default Home;
