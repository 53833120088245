import Button from "react-bootstrap/Button";
import ProjectItem from "./ProjectItem";
import workerImg from "../workerImg.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function CurrentProjects(props) {
  let showProjects;

  showProjects = (
    <div>
      <div className="projectsRow col-12">
        <div>
          <Button
            variant="primary"
            onClick={() => window.location.assign("/select-ward")}
          >
            Back
          </Button>
        </div>
        <h2>My Ward | {props.wardNum} | Current Projects</h2>
      </div>
      Click on a project to view more <br />
      {ProjectItem(
        workerImg,
        "Widening of Narnia Road",
        "HM Civils",
        "34",
        "9",
        "R25k",
        "1 Oct 2020 to 31 Dec 2022",
        props.wardNum,
        props.saveProjData
      )}
      {ProjectItem(
        workerImg,
        "Widening of Narnia Road",
        "HM Civils",
        "34",
        "9",
        "R25k",
        "1 Oct 2020 to 31 Dec 2022",
        props.wardNum,
        props.saveProjData
      )}
      {ProjectItem(
        workerImg,
        "Widening of Narnia Road",
        "HM Civils",
        "34",
        "9",
        "R25k",
        "1 Oct 2020 to 31 Dec 2022",
        props.wardNum,
        props.saveProjData
      )}
    </div>
  );

  return <div className="normalPageDiv">{showProjects}</div>;
}

export default CurrentProjects;
