import { Link } from "react-router-dom";
import "../App.css";

function WideBlocks(link, heading, text) {
  return (
    <Link to={link} className="wideBlocks col-12">
      {" "}
      <div>
        <h5>{heading}</h5>
        <p>{text}</p>
      </div>
    </Link>
  );
}

export default WideBlocks;
