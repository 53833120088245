import { Link } from "react-router-dom";
import "../App.css";

function ThirdBlockItems(link, heading, text) {
  return (
    <Link to={link} className="opportunitiesItems col-6">
      <h5>{heading}</h5>
      <p>{text}</p>
    </Link>
  );
}

export default ThirdBlockItems;
