import { Form, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import "../App.css";

import React, { useState } from "react";

function SearchJobs() {
  const [result, setResult] = useState(null);

  function setAlert(e) {
    setResult(e.target.value);
  }

  function clearForm() {
    setResult(null);
    document.forms["searchForm"].reset();
  }

  return (
    <div className="normalPageDiv">
      <h2>Search for Available Jobs in my Ward</h2>
      <div className="searchFormDiv">
        <Form id="searchForm" autoComplete="off">
          <Row className="mb-2 col-12 col-md-9">
            <Col className="mb-2 col-3 col-md-2">
              <Form.Label>Ward #</Form.Label>
            </Col>
            <Col className="mb-2 col-md-8 col-12">
              <Form.Group>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setAlert(e)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Button variant="primary" size="lg">
                Search
              </Button>
            </Col>
          </Row>
        </Form>{" "}
      </div>
      <div className="jobsTable table-responsive">
        <Table striped bordered hover className="col-12">
          <thead>
            <tr>
              <th>Contractor</th>
              <th>Position</th>
              <th>Workers</th>
              <th>Start</th>
              <th>Days</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Hm civils</td>
              <td>Bricklayer</td>
              <td>11</td>
              <td>1 Nov 2021</td>
              <td>23</td>
              <td>Apply</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default SearchJobs;
