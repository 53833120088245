import React from "react";
import "isomorphic-fetch";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { Routes, Route } from "react-router-dom";

// Import components
import ScrollToTop from "./Components/ScrollToTop";
import Header from "./Components/Header";
import Home from "./Components/Home";
import SelectWard from "./Components/SelectWard";
import Footer from "./Components/Footer";
import SearchJobs from "./Components/SearchJobs";
import SearchSubcontracts from "./Components/SearchSubcontracts";
import SubmitCv from "./Components/SubmitCv";
import BecomeClo from "./Components/BecomeClo";
import ContactWorkSeekers from "./Components/ContactWorkSeekers";
import Training from "./Components/Training";
import Contact from "./Components/Contact";
import About from "./Components/About";
import Legal from "./Components/Legal";
import Register from "./Components/Register";
import Login from "./Components/Login";
import CurrentProjects from "./Components/CurrentProjects";
import SpecificWard from "./Components/SpecificWard";
import SpecificProject from "./Components/SpecificProject";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: [],
      projectName: "",
      contractorName: "",
      numOfJobs: "",
      numOfSubcontractors: "",
      CSI: "",
      estDates: "",
      wardNumber: null,
    };

    this.updateWard = this.updateWard.bind(this);
    this.saveProjData = this.saveProjData.bind(this);
  }

  saveProjData(project, contractor, jobs, subcontractors, csi, dates, wardnum) {
    this.setState(
      {
        projectName: project,
        contractorName: contractor,
        numOfJobs: jobs,
        numOfSubcontractors: subcontractors,
        CSI: csi,
        estDates: dates,
        wardNumber: wardnum,
      },
      () =>
        console.log(
          "update ward has run. Ward number is: " + this.state.wardNumber
        )
    );
  }

  updateWard(wardNum) {
    this.setState({ wardNumber: wardNum }, () =>
      console.log(
        "update ward has run. Ward number is: " + this.state.wardNumber
      )
    );
  }

  // componentDidMount() {
  //   fetch("/data")
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         this.setState(
  //           {
  //             isLoaded: true,
  //             items: result.message,
  //           },
  //           () => {
  //             console.log("Data from backend is: " + this.state.items);
  //           }
  //         );
  //       },
  //       (error) => {
  //         this.setState({
  //           isLoaded: true,
  //           error,
  //         });
  //       }
  //     );
  // }

  render() {
    const {
      error,
      isLoaded,
      items,
      wardNumber,
      projectName,
      contractorName,
      numOfJobs,
      numOfSubcontractors,
      CSI,
      estDates,
    } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="App">
          {/* <p>{items}</p> */}
          <ScrollToTop />
          <Header />
          <div className="body">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/select-ward"
                element={
                  <SelectWard
                    wardNum={this.state.wardNumber}
                    updateWard={this.updateWard}
                  />
                }
              />
              <Route
                path="/select-ward/:wardnum"
                element={
                  <SpecificWard
                    wardNum={this.state.wardNumber}
                    updateWard={this.updateWard}
                  />
                }
              />
              <Route
                path="/select-ward/:wardnum/current-projects"
                element={
                  <CurrentProjects
                    wardNum={this.state.wardNumber}
                    saveProjData={this.saveProjData}
                  />
                }
              />
              <Route
                path="/select-ward/:wardnum/current-projects/:projectname"
                element={
                  <SpecificProject
                    wardNum={wardNumber}
                    projectName={projectName}
                    contractorName={contractorName}
                    numOfJobs={numOfJobs}
                    numOfSubcontractors={numOfSubcontractors}
                    csi={CSI}
                    estDates={estDates}
                  />
                }
              />

              <Route path="/search-jobs" element={<SearchJobs />} />
              <Route
                path="/search-subcontracts"
                element={<SearchSubcontracts />}
              />
              <Route
                path="/become-a-community-liaison-officer"
                element={<BecomeClo />}
              />
              <Route path="/training" element={<Training />} />
              <Route
                path="/contact-work-seekers"
                element={<ContactWorkSeekers />}
              />
              <Route path="/submit-cv" element={<SubmitCv />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/legal" element={<Legal />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
          <Footer />
        </div>
      );
    }
  }
}

export default App;
