import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CWSFormRow from "./CWSFormRow";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

import React, { useState } from "react";

function ContactWorkSeekers() {
  const [result, setResult] = useState(null);

  return (
    <div className="normalPageDiv">
      <h2>Contact Work Seekers</h2>
      <p>
        Work seekers include skilled, semi-skilled and unskilled workers, as
        well as subcontractors. Hundreds are waiting for you to contact them via
        their community liaison officer. Leave your details and a CLO will be in
        touch.
      </p>

      <div className="contactWorkSeekersFormDiv">
        <Form id="contactWorkSeekersForm" autoComplete="off">
          {CWSFormRow("Name", "text")}
          {CWSFormRow("Surname", "text")}
          {CWSFormRow("Email", "email")}
          {CWSFormRow("Cell", "text")}
          {CWSFormRow("Contractor Name", "text")}
          {CWSFormRow("Project Name", "text")}
          {CWSFormRow("Ward", "text")}

          <Row className="mb-2">
            <Col>
              <Button variant="primary" size="lg">
                Please Contact Me
              </Button>
            </Col>
          </Row>
        </Form>{" "}
      </div>
    </div>
  );
}

export default ContactWorkSeekers;
