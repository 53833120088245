import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function About() {
  return (
    <div className="normalPageDiv">
      <h2>About</h2>
      Info about us
    </div>
  );
}

export default About;
