import workerImg from "../workerImg.jpg";
import CourseBlock from "./CourseBlock";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function Training() {
  return (
    <div className="trainingDiv">
      <h2>Expand your Opportunities Through Training</h2>
      <div className="row mt-3">
        {CourseBlock(workerImg, "CLO On-Boarding Online Course")}
        {CourseBlock(workerImg, "CLO On-Boarding Online Course")}
        {CourseBlock(workerImg, "CLO On-Boarding Online Course")}
        {CourseBlock(workerImg, "CLO On-Boarding Online Course")}
      </div>
    </div>
  );
}

export default Training;
