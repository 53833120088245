import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CvFormRow from "./CvFormRow";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

import React, { useState } from "react";

function SubmitCv() {
  const [result, setResult] = useState(null);

  function setAlert(e) {
    setResult(e.target.value);
  }

  function alertIt() {
    alert(result);
  }

  return (
    <div className="normalPageDiv">
      <h2>Submit CV</h2>
      <div className="submitCvFormDiv">
        <Form id="submitCvForm" autoComplete="off">
          {CvFormRow("Name", "text")}
          {CvFormRow("Surname", "text")}
          {CvFormRow("Email", "email")}
          {CvFormRow("Cell", "text")}
          {CvFormRow("ID #", "text")}
          {CvFormRow("or Passport #", "text")}
          {CvFormRow("Address", "text")}
          {CvFormRow("Ward", "text")}

          <Row className="mb-2">
            <Col>
              <Button variant="primary" size="lg">
                Submit CV
              </Button>
            </Col>
          </Row>
        </Form>{" "}
      </div>
    </div>
  );
}

export default SubmitCv;
