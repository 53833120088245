import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function Login() {
  return (
    <div className="normalPageDiv">
      <h2>Login</h2>
      <div className="loginRow row">
        <div className="loginLeft col-12 col-md-6">
          <h5>Use a local account to log in</h5>
          <div className="loginFormDiv col-12">
            <Form id="loginForm" autoComplete="off">
              <Row className="mb-2">
                <Col md={2}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Group>
                    <Form.Control size="lg" type="email" placeholder="..." />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md={2}>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Group>
                    <Form.Control size="lg" type="password" placeholder="..." />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  {" "}
                  <Form.Check
                    type="checkbox"
                    id="remember-me"
                    label="Remember me?"
                  />
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md={3}>
                  <Button variant="primary" size="lg">
                    Log in
                  </Button>
                </Col>
              </Row>
            </Form>{" "}
          </div>
          <div>
            {" "}
            <br />
            <Link to="/register" className="greyText">
              <h5>Register as new user</h5>
            </Link>
          </div>
        </div>

        <div className="loginRight col-12 col-md-6">
          <h5>Use another service to log in</h5>
          <Button variant="primary" size="lg" className="otherLoginButtons">
            Google
          </Button>
          <Button variant="primary" size="lg" className="otherLoginButtons">
            Facebook
          </Button>
          <Button variant="primary" size="lg" className="otherLoginButtons">
            Twitter
          </Button>
          <Button variant="primary" size="lg" className="otherLoginButtons">
            Microsoft
          </Button>
        </div>
      </div>

      {/* Page container  */}
    </div>
  );
}

export default Login;
