import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function Register() {
  return (
    <div className="normalPageDiv">
      <h2>Register</h2>
      <h5>Create a New Account:</h5>
      <div className="registerFormDiv col-12">
        <Form id="registerForm" autoComplete="off">
          <Row className="mb-2">
            <Col md={2}>
              <Form.Label>Email</Form.Label>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Control size="lg" type="email" placeholder="..." />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={2}>
              <Form.Label>Password</Form.Label>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Control size="lg" type="password" placeholder="..." />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={2}>
              <Form.Label>Confirm Password</Form.Label>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Control size="lg" type="password" placeholder="..." />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <Button variant="primary" size="lg">
                Register
              </Button>
            </Col>
          </Row>
        </Form>{" "}
      </div>
    </div>
  );
}

export default Register;
