import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "../App.css";

function CourseBlock(imgSrc, title) {
  return (
    <div className="trainingCourse col-12 col-md-6 mb-3">
      <Image
        src={imgSrc}
        alt={title}
        className="courseImage col-12 col-md-10"
      />
      <div className="courseTitle">{title}</div>
      <div className="courseButton">
        {" "}
        <Button variant="outline-danger" size="sm">
          Access Course
        </Button>{" "}
      </div>
    </div>
  );
}

export default CourseBlock;
