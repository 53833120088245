import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CloFormRow from "./CvFormRow";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

import React, { useState } from "react";

function BecomeClo() {
  const [result, setResult] = useState(null);

  function setAlert(e) {
    setResult(e.target.value);
  }

  function alertIt() {
    alert(result);
  }

  return (
    <div className="normalPageDiv">
      <h2>Become a CLO (community liaison officer)</h2>
      <p>
        All contractors are required to appoint a community liaison officer
        (CLO) from the ward where the project is taking place. The CLO is the
        liaison between the contractor and the community, and is responsible
        for:
        <ul>
          <li>item 1</li>
          <li>item 2</li>
          <li>item 3</li>
        </ul>
      </p>
      <p>Make a difference. Become a CLO.</p>

      <div className="becomeCloFormDiv">
        <Form id="becomeCloForm" autoComplete="off">
          {CloFormRow("Name", "text")}
          {CloFormRow("Surname", "text")}
          {CloFormRow("Email", "email")}
          {CloFormRow("Cell", "text")}

          <Row className="mb-2">
            <Col>
              <Button variant="primary" size="lg">
                Please Contact Me
              </Button>
            </Col>
          </Row>
        </Form>{" "}
      </div>
    </div>
  );
}

export default BecomeClo;
