import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

// Displays ward search bar or specific ward page (if ward is selected)
function SelectWard(props) {
  const [formInput, setFormInput] = useState(null);
  const [result, setResult] = useState(null);
  const [redirect, setRedirect] = useState(false);

  let showForm;

  function handleInput(e) {
    setResult(e.target.value);
  }

  function alertIt() {
    setFormInput(result);
    setRedirect(null);
    props.updateWard(result);
  }

  function changeWard() {
    setRedirect(true);
    setResult(null);
    setFormInput(null);
    props.updateWard(formInput);
  }

  if (formInput === null || redirect === true) {
    showForm = (
      <div className="normalPageDiv">
        <h2>My Ward | Projects and Opportunities</h2>
        <div className="wardSearchForm">
          <Form>
            <Row className="mb-3">
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handleInput(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Button variant="primary" size="lg" onClick={() => alertIt()}>
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );

    // Redirect to specific ward page
  } else {
    let path = `/select-ward/${props.wardNum}`;
    console.log("Path is: " + path);
    showForm = <Navigate to={path} />;
  }

  return showForm;
}

export default SelectWard;
