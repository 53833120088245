import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function Contact() {
  return (
    <div className="normalPageDiv">
      <h2>Contact</h2>
      <ul>
        <li>
          <b>Address:</b> 187 Tara Road, Bluff, Durban
        </li>
        <li>
          <b>Email:</b> ward66.business@gmail.com
        </li>
        <li>
          <b>Phone:</b> +27 65 810 1403
        </li>
      </ul>
    </div>
  );
}

export default Contact;
