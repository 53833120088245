import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function SpecificWard(props) {
  const [redirect, setRedirect] = useState(false);
  function redirectPage() {
    setRedirect(true);
  }
  let showPage;
  let numOfProjects = 13;
  let numOfSuggest = 435;
  let numOfFutureProjs = 7;

  let pathToProject = `/select-ward/my-ward-${props.wardNum}/current-projects`;
  if (redirect === false) {
    showPage = (
      <div className="normalPageDiv">
        <h2>My Ward | Projects and Opportunities</h2>
        <div className="wardBody">
          <div className="flexRow col-12 col-md-4">
            <div className="col-6">
              Selected Ward: <b> {props.wardNum} </b>
            </div>

            <div className="col-6">
              <Button
                variant="primary"
                size="sm"
                onClick={() => redirectPage()}
              >
                Try Another Ward
              </Button>
            </div>
          </div>
          <div className="wardData">
            <ul>
              <li>
                Municipality: <b>eThekwini</b>
              </li>
              <li>
                Mayor: <b>Sbu Ndebele</b>
              </li>
              <li>
                Ward Counsellor: <b>Fred Basset</b>
              </li>
              <li>
                Current CLOs: <b>Suzie Smith</b>
              </li>
            </ul>
          </div>

          <div className="wardButtons row">
            <Link to={pathToProject} className="wardButtonItem col-6">
              <div>
                Current Projects &nbsp;
                <span style={{ color: "red" }}>{numOfProjects}</span>
              </div>{" "}
            </Link>

            <Link to="/" className="wardButtonItem col-6">
              <div>
                Future Projects &nbsp;
                <span style={{ color: "red" }}>{numOfFutureProjs}</span>
              </div>
            </Link>

            <Link to="/" className="wardButtonItem col-6">
              <div>
                Suggestions &nbsp;
                <span style={{ color: "red" }}>{numOfSuggest}</span>
              </div>{" "}
            </Link>

            <Link to="/" className="redButton col-6">
              <div>Whistleblower</div>
            </Link>

            <Link to="/search-jobs" className="wardButtonItem col-6">
              <div> Search For Jobs</div>
            </Link>

            <Link to="/search-subcontracts" className="wardButtonItem col-6">
              <div> Search for Subcontracts</div>
            </Link>

            <Link to="/submit-cv" className="wardButtonItem col-6">
              <div> Submit my CV</div>
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    showPage = <Navigate to={"/select-ward"} />;
  }
  return showPage;
}

export default SpecificWard;
